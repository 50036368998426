<template>
  <CmsButtonBase
    :data-test="renderTinyButton ? 'cms-button-tiny' : 'cms-button-normal'"
    :rel="ctaLink?.link?.rel"
    :size="renderTinyButton ? 'small' : 'medium'"
    :target="ctaLink?.link?.target"
    :title="ctaLink?.label"
    :to="ctaLink?.link?.href"
    :variant="variant"
    :width="width"
    @click="handleClick"
  >
    {{ ctaLink?.label }}
  </CmsButtonBase>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type CmsButtonProps } from '@backmarket/http-api/src/api-specs-content/models/cms-button'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import type { ContentBlockProps } from '../../models/content-block'
import CmsButtonBase from '../../shared-components/CmsButtonBase/CmsButtonBase.vue'

const props = withDefaults(
  defineProps<
    CmsButtonProps &
      ContentBlockProps & { fullWidth?: 'always' | 'fixed' | 'adaptive' }
  >(),
  {
    size: 'normal',
    variant: 'primary',
    fullWidth: 'always',
  },
)

const width = computed(() => {
  switch (props.fullWidth) {
    case 'always':
      return 'full-width'
    case 'fixed':
      return 'fixed'
    case 'adaptive':
      return 'adaptive'
    default:
      return 'adaptive'
  }
})

const { trackClick } = useTracking()
const renderTinyButton = computed(() => props.size === 'tiny')

function handleClick() {
  trackClick({
    ...(props.tracking || {}),
    name: props.ctaLink?.label,
  })
}
</script>
