export type ExperimentName = `experiment.${string}`
type Experiment = {
  defaultValue: 'noVariation'
  urlPatterns: Array<string>
}

/**
 * This dictionary holds the configuration for all the experiments currently
 * being assigned by the experiments worker.
 *
 * @property defaultValue - Must be set to noVariation for all of them.
 * @property urlPatterns - Allows you to scope each experiment to their
 * associated set of pages. Those patterns are used on the worker side
 * to know if the experiment will be used to process the current request
 * or not. It helps us improving our cache hit ratio, and thus the
 * overall server-side performance.
 */
export const config = {
  'experiment.globalAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.homeAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/?$' /* Home page */],
  },
  'experiment.landingAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/l/.*' /* Landing page */],
  },
  'experiment.productAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.checkoutAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.homeCMSPageName': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/?$' /* Home page - permanent AB test */],
  },
  'experiment.ppSplitPriceEuropeVersion': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppSplitPriceUSVersion': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.serviceFeePriceGroup24': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.homeBuybackPageName': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/buyback/.*' /* All Buyback Pages */],
  },
  'experiment.ppBuybackDropdown': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppBouyguesPosition': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppBouyguesOffersFilter': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutB&YOU1999Offer
     * withoutB&YOU1499Offer
     * noFilter
     *  */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppMixAndMatchEverywhere': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.catchUpModalRevamp': {
    defaultValue: 'noVariation',
    /** Variations:
     *  oldCatchUpModal
     * 	newCatchUpModal
     */
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/f2/.*',
      /* Checkout pages */
    ],
  },
  'experiment.insuranceMonthlySubscription': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.deliveryMethodPosition': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.insuranceBenefitList': {
    defaultValue: 'noVariation',
    /** Variations:
     * insuranceBenefitFullList
     * insuranceBenefitShortList */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.fakeBytelMobilePlan': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.killOneyFr': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.enableClearpayUK': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages (in the footer) */],
  },
  'experiment.enableKlarnaIT': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages (in the footer) */],
  },
  'experiment.ppPremiumGrade': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppImmersiveStories': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppRealGradePicture': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutRealPicture
     * withRealPicture */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppHideBatteryStep': {
    defaultValue: 'noVariation',
    /** Variations:
     * showBattery
     * hideBatteryInUsAndPremium */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppDiscountedSwap': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutDiscountedSwap
     * withDiscountedSwap */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.newNavigation': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.displayEditedReviews': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutEditedReviews
     * withEditedReviews */
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */,
      '^/[a-z]{2}-[a-z]{2}/l/.*' /* PLP page */,
      '^/[a-z]{2}-[a-z]{2}/s/.*' /* Seller page */,
      '^/[a-z]{2}-[a-z]{2}/r/.*' /* Reviews pages */,
    ],
  },
  'experiment.paymentFormVersion': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/payment*' /* Payment page */],
  },
  'experiment.buybackHeader': {
    /** Variations:
     * withoutMinimal
     * withMinimal */
    defaultValue: 'noVariation',
    urlPatterns: [
      '^/[a-z]{2}-[a-z]{2}/buyback-funnel/.*' /* Buyback funnel pages */,
    ],
  },
} satisfies Record<ExperimentName, Experiment>
