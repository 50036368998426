import type { RouteRecordRaw } from 'vue-router'

import { INSURANCE } from './routes.constants'

const routes: RouteRecordRaw[] = [
  {
    path: 'post-purchase/insurance/:policyId/due',
    name: INSURANCE.DUE_PAYMENT,
    component: () => import('./pages/DueInsurance/DueInsurance.vue'),
    meta: {
      layout: false,
      auth: {
        required: true,
      },
    },
  },
  {
    path: 'post-purchase/insurance/due/completed',
    name: INSURANCE.DUE_PAYMENT_COMPLETED,
    component: () => import('./pages/DueInsurance/DueInsuranceCompleted.vue'),
    meta: {
      auth: {
        required: true,
      },
    },
  },
  {
    path: 'post-purchase/insurance',
    name: INSURANCE.POST_PURCHASE,
    component: () => import('./pages/PostPurchase/PostPurchase.vue'),
    meta: {
      layout: false,
      auth: {
        required: true,
      },
    },
  },
  {
    path: 'post-purchase/insurance/expired',
    name: INSURANCE.EXPIRED,
    component: () => import('./pages/Expired/Expired.vue'),
  },

  // Backwards compatible route for old url
  {
    path: 'checkout',
    redirect: ({ query }) => ({ name: INSURANCE.POST_PURCHASE, query }),
  },
]

export default routes
